import React, { Component } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { prop } from 'ramda';
import { formatInputProps, inputPropTypes } from './decorators';
import { Error, metaProptypes } from '../../atoms/Error';

class Input extends Component {
  componentDidUpdate(prevProps) {
    const { meta } = prevProps;
    const { meta: nextMeta } = this.props;

    if (!meta.active && nextMeta.active) this.inputRef.focus();
  }

  render() {
    const {
      label,
      inputProps,
      meta,
      pending,
      className,
      margin,
      align,
      icon,
      isSubmitted,
      formDirtySinceLastSubmit,
      disabled,
      isDisplayErrorOnSubmit: isOnSubmit,
      isDisplayErrorOnDirty: isOnDirty,
      isAlwaysVisible,
      fromReduxForm,
      showErrorWhen
    } = this.props;

    const error = prop('error', meta);
    const touched = prop('touched', meta);
    const hasReduxFormError = fromReduxForm && !!error && touched;

    return (
      <label className={`input ${margin ? 'mb-' + margin : 'mb-double'} ${className}`}>
        <span className={`input_content ${icon && 'hasicon icon-' + icon}`}>
          <input
            className={`input_box ${pending ? 'loading' : ''} ${align ? 't-' + align : ''}`}
            {...inputProps}
            ref={(input) => {
              this.inputRef = input;
            }}
            disabled={disabled}
          />
          {!!label && <span className="label">{label}</span>}
          {!!pending && <i className="input_loader loader" />}
        </span>
        {!!hasReduxFormError && <span className="error">{error}</span>}
        {showErrorWhen && showErrorWhen(meta) && <Error {...meta} isAlwaysVisible={true} />}
        {!showErrorWhen && (
          <Error {...{ isSubmitted, isAlwaysVisible, isOnDirty, isOnSubmit, formDirtySinceLastSubmit }} {...meta} />
        )}
      </label>
    );
  }

  static defaultProps = {
    meta: {
      error: '',
      touched: false
    },
    pending: false,
    className: '',
    disabled: false,
    isAlwaysVisible: false,
    fromReduxForm: false
  };

  static propTypes = {
    label: string,
    className: string,
    inputProps: inputPropTypes,
    meta: shape(metaProptypes),
    pending: bool,
    align: string,
    icon: string,
    isSubmitted: bool,
    isDisplayErrorOnDirty: bool,
    isDisplayErrorOnSubmit: bool,
    disabled: bool,
    formDirtySinceLastSubmit: bool,
    margin: string,
    isAlwaysVisible: bool,
    fromReduxForm: bool,
    showErrorWhen: func
  };
}

export default formatInputProps()(Input);
