import React from 'react';
import InputWithLabel from '../../molecules/form/InputWithLabel';
import { errorMessageOf } from './errors';
import { isAnEmail, isNotEmpty } from '../../../utils/validator';
import { object } from 'prop-types';
import { useTranslate } from '../../../translate/I18n';

/**
 * @param {function(string):string} t
 * @param {FormContextValues} formHook
 * @param {*} props
 * @returns {*}
 */
const Email = ({ formHook, name, errorMessage, ...props }) => {
  const { t } = useTranslate(['validation', 'common']);
  return (
    <InputWithLabel
      name={name}
      label={t('common:email')}
      type="email"
      block={true}
      errorMessage={errorMessage || errorMessageOf(t, name, formHook.errors)}
      ref={formHook.register({
        validate: {
          fillEmail: isNotEmpty,
          wrongEmail: isAnEmail
        }
      })}
      placeholder={t('common:emailPlaceholder')}
      {...props}
    />
  );
};

Email.propTypes = {
  ...InputWithLabel.propTypes,
  formHook: object
};
Email.defaultProps = {
  name: 'email'
};

export default Email;
