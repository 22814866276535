import React from 'react';
import { bool, func, oneOfType, string } from 'prop-types';

export const metaProptypes = {
  touched: bool,
  dirty: bool,
  error: oneOfType([string, bool]),
  isOnSubmit: bool,
  isOnDirty: bool,
  isSubmitted: bool,
  visited: bool,
  submitError: string,
  formDirtySinceLastSubmit: bool,
  isAlwaysVisible: bool,
  isOnTouched: bool,
  submitFailed: bool
};

// @TODO: REFACTOR ERROR LOGIC ONCE SCENARIOS ARE DEFINED

const isOnSubmitAndSubmitted = ({ isOnSubmit, isSubmitted }) => isOnSubmit && isSubmitted;
const isOnDirtyAndDirty = ({ isOnDirty, dirty }) => isOnDirty && dirty;
const isOnTouchedAndTouched = ({ isOnTouched, touched }) => isOnTouched && touched;

const shouldShowError = ({ isOnSubmit, isSubmitted, isOnDirty, isOnTouched, dirty, visited, touched }) =>
  isOnSubmitAndSubmitted({ isOnSubmit, isSubmitted }) ||
  isOnDirtyAndDirty({ isOnDirty, dirty }) ||
  isOnTouchedAndTouched({ isOnTouched, touched }) ||
  (!isOnDirty && !isOnSubmit && !isOnTouched && visited);

export const Error = ({
  dirty,
  error,
  submitError,
  submitFailed,
  formDirtySinceLastSubmit,
  isAlwaysVisible,
  isOnSubmit,
  isOnDirty,
  isOnTouched,
  isSubmitted,
  visited,
  touched
}) =>
  submitError && !formDirtySinceLastSubmit ? (
    <span className="error">{submitError}</span>
  ) : error &&
    (isAlwaysVisible ||
      shouldShowError({ dirty, isOnSubmit, isOnDirty, isOnTouched, isSubmitted, visited, touched }) ||
      submitFailed) ? (
    <span className="error">{error}</span>
  ) : null;

Error.propTypes = metaProptypes;