import React, { useState } from 'react';
import styled from 'styled-components';
import { func, object } from 'prop-types';

import { commonRoutes } from '../../routes';
import ModalForgotPassword from './ModalForgotPassword';
import { useForm } from 'react-hook-form';
import Email from '../elements/formHook/Email';
import Password from '../elements/formHook/Password';

import Box from '../atoms/Box';
import Button from '../atoms/Button';
import Text from '../atoms/Text';
import { useTranslate } from '../../translate/I18n';
import { toast } from 'react-toastify';
import { handle, setFormHookError, fallbackErrorHandler } from '../../infrastructure/validation/error';
import Link from '../atoms/Link';

const { general: { register } } = commonRoutes;

const StyledFormWrapper = styled('div')(({ theme }) => ({
  maxWidth: '500px',
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  right: '17%',
  zIndex: 2,
  padding: `${theme.space[4]}px ${theme.space[3]}px`,
  display: 'block',
  '@media (max-height: 700px)': {
    position: 'static',
    marginLeft: 'auto',
    marginRight: 'auto',
    transform: 'translateY(0)'
  },
  '@media (max-width: 1700px)': {
    right: '15%'
  },
  '@media (max-width: 1500px)': {
    right: '10%'
  },
  '@media (max-width: 1200px)': {
    right: '5%'
  },
  '@media (max-width: 900px)': {
    transform: 'translateY(0)',
    position: 'static',
    margin: 'auto',
    textAlign: 'center'
  }
}));

const LoginForm = ({ login }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const formHook = useForm();
  const { t, i18n } = useTranslate(['auth']);

  const { handleSubmit, formState, setError } = formHook;
  const onSubmit = async credentials => {
    try {
      await login(credentials);
    } catch (error) {
      handle(error).with(setFormHookError(setError), fallbackErrorHandler(toast.error, t));
    }
  };

  return (
    <>
      <StyledFormWrapper>
        <Text tag="h1" styleName="wH1">
          {t('auth:loginTitle')}
        </Text>
        <Text tag="h2" mb={6} styleName="wPNormal">
          {t('auth:loginSubtitle')}
        </Text>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Email formHook={formHook} />
          <Password formHook={formHook} strongCheck={false} placeholder="•••••••" />
          <Button
            type="submit"
            shape="rounded"
            display="block"
            width={{ _: 1, sm: 1, md: 'auto' }}
            mt={{ _: 10, sm: 10, md: 0 }}
            loading={formState.isSubmitting}>
            {t('auth:signIn')}
          </Button>
        </form>
        <Box mt={8}>
          <Link type="tertiary" to={register.to}><Button
            styleType="transparent"
            data-cy="link-register2">
            {t('auth:noAccountYet')}
          </Button>
          </Link>
        </Box>
        <Box mt={2}>
          <Button styleType="transparent" onClick={() => setIsModalOpen(true)}>
            {t('auth:iForgotPassword')}
          </Button>
        </Box>
      </StyledFormWrapper>

      <ModalForgotPassword isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </>
  );
};

LoginForm.propTypes = {
  login: func
};

export default LoginForm;
