import React from 'react';
import styled from 'styled-components';
import { typography, color } from 'styled-system';
const StyledSvg = styled('svg')(typography, color);

const Eye = props => (
  <StyledSvg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M22.822 11.566C20.618 6.45 16.472 3.275 12 3.275c-4.473 0-8.618 3.175-10.822 8.29a1.09 1.09 0 000 .874c2.204 5.116 6.35 8.29 10.822 8.29 4.473 0 8.618-3.174 10.822-8.29a1.09 1.09 0 000-.873zM12 18.548c-3.47 0-6.73-2.498-8.618-6.546C5.269 7.955 8.53 5.457 12 5.457c3.47 0 6.73 2.498 8.618 6.545-1.887 4.048-5.149 6.546-8.618 6.546zm0-10.91a4.364 4.364 0 100 8.728 4.364 4.364 0 000-8.727z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </StyledSvg>
);
export default Eye;
