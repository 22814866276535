import React from 'react';
import { errorMessageOf } from './errors';
import { bool, object } from 'prop-types';
import { useTranslate } from '../../../translate/I18n';
import { checkPassword } from '../../molecules/PasswordChecker';
import InputPasswordWithLabel from '../../molecules/form/InputPasswordWithLabel';
import { isNotEmpty } from '../../../utils/validator';

const strongValidation = { password: checkPassword };
const weakValidation = { cantBeEmpty: isNotEmpty };

/**
 * @param {function(string):string} t
 * @param {FormContextValues} formHook
 * @param {*} props
 * @param {boolean} strongCheck
 * @returns {*}
 * @constructor
 */
const Password = ({ formHook, errorMessage, strongCheck, ...props }) => {
  const { t } = useTranslate(['validation', 'common']);
  const validate = strongCheck ? strongValidation : weakValidation;
  return (
    <InputPasswordWithLabel
      label={t('common:password')}
      errorMessage={errorMessage || errorMessageOf(t, props.name || 'password', formHook.errors)}
      ref={formHook.register({ validate })}
      name="password"
      placeholder={t('common:passwordPlaceholder')}
      {...props}
    />
  );
};
Password.defaultProps = {
  strongCheck: true
};
Password.propTypes = {
  ...InputPasswordWithLabel.propTypes,
  formHook: object,
  strongCheck: bool
};

export default Password;
