import React, { useState } from 'react';
import styled from 'styled-components';
import { oneOf } from 'prop-types';
import { variant } from 'styled-system';
import { transparentize } from 'polished';

import { inputPropTypesObject } from '../../elements/form/decorators';
import Eye from '../icons/Eye';

const StyledInput = styled('input')(({ theme }) => ({
  outline: 'none',
  border: 'none',
  display: 'inline-block',
  height: 'calc(100% - 4px)',
  lineHeight: '22px',
  width: 'calc(100% - 40px)',
  marginTop: '2px',
  marginLeft: '2px',
  borderRadius: theme.radii[2],
  paddingLeft: theme.space[2],
  paddingRight: theme.space[2],
  cursor: 'inherit',
  background: theme.colors.transparent,
  '&::placeholder': {
    color: theme.colors.grey
  },
  '&:disabled': {
    cursor: 'not-allowed'
  }
}));

const StyledIcon = styled('div')(({ theme, toggled }) => ({
  width: '40px',
  height: '40px',
  cursor: 'pointer',
  padding: theme.space[1],
  fontSize: '24px',
  color: theme.colors.black,
  ...(toggled && {
    color: theme.colors.main
  })
}));

const StyledContainer = styled('div')(
  ({ theme, disabled }) => ({
    width: '100%',
    height: '40px',
    display: 'inline-flex',
    borderRadius: theme.radii[2],
    border: 'none',
    cursor: 'text',
    marginTop: '28px',
    boxShadow: `0 0 0 1px ${theme.colors.greyVerylight} inset`,
    color: theme.colors.dark,
    boxSizing: 'border-box',
    backgroundColor: theme.colors.greyVerylight,
    transition: 'box-shadow .3s ease',
    position: 'relative',
    appearance: 'none',
    '&[data-focus="true"]': {
      boxShadow: `0 0 0 2px ${theme.colors.greyLight} inset`
    },
    ...(disabled && {
      boxShadow: `0 0 0 2px ${theme.colors.greyVerylight} inset`,
      backgroundColor: theme.colors.greyVerylight,
      cursor: 'not-allowed'
    })
  }),
  ({ theme }) =>
    variant({
      prop: 'state',
      variants: {
        success: {
          boxShadow: `0 0 0 2px ${theme.colors.blue} inset !important`,
          backgroundColor: transparentize(0.9, theme.colors.blue)
        },
        error: {
          boxShadow: `0 0 0 2px ${theme.colors.red} inset !important`,
          backgroundColor: transparentize(0.9, theme.colors.red)
        }
      }
    })
);

const PasswordField = React.forwardRef(({ onFocus, onBlur, state, ...props }, ref) => {
  const [visible, setVisible] = useState(false);
  const [focus, setFocus] = useState(false);

  return (
    <StyledContainer state={state} data-focus={focus} data-labelizable={true}>
      <StyledInput
        {...props}
        ref={ref}
        type={visible ? 'text' : 'password'}
        onFocus={(e) => {
          e.target.select();
          setFocus(true);
          if (onFocus) {
            onFocus(e);
          }
        }}
        onBlur={(e) => {
          if (onBlur) {
            onBlur(e);
          }
          setFocus(false);
        }}
      />
      <StyledIcon toggled={visible}>
        <Eye onClick={() => setVisible(!visible)} />
      </StyledIcon>
    </StyledContainer>
  );
});

PasswordField.propTypes = {
  ...inputPropTypesObject,
  state: oneOf(['success', 'error', 'default'])
};

export default PasswordField;
