import React from 'react';
import PropTypes from 'prop-types';
import Button from '../atoms/Button';
import { isNotEmpty, isAnEmail, validateForm } from '../../utils/validator';
import { useTranslate } from '../../translate/I18n';
import Input from './form/Input';
import { FormProvider, useForm } from 'react-hook-form';
import InputWithLabel from '../molecules/form/InputWithLabel';

const validationRules = ({ t }) => ({
  email: [
    '',
    [
      [isNotEmpty, t('cantBeEmpty')],
      [isAnEmail, t('wrongEmail')]
    ]
  ]
});

const EmailForm = ({ onFormSubmit, initialValues }) => {
  const { t } = useTranslate(['validation']);
  const formHook = useForm({ defaultValues: initialValues });
  const { register, handleSubmit, watch, errors } = formHook;
  return (
    <FormProvider {...formHook}>
      <form onSubmit={handleSubmit((values) => onFormSubmit(values.email))} noValidate>
        <InputWithLabel
          name="email"
          type="email"
          placeholder={t('common:emailPlaceholder')}
          label={`${t('common:email')}`}
          ref={register({
            validate: {
              isNotEmpty: v => isNotEmpty(v) || t('cantBeEmpty'),
              isAnEmail: v => isAnEmail(v) || t('wrongEmail')
            }
          })}
          errorMessage={errors.email?.message}
        />
        <Button type="submit" className="m-auto block" disabled={false}>
          {t('common:send')}
        </Button>
      </form>
    </FormProvider>
  );
};

EmailForm.propTypes = {
  onFormSubmit: PropTypes.func,
  initialValues: PropTypes.shape({
    email: PropTypes.string
  }),
  errorEmail: PropTypes.bool
};

EmailForm.initialValues = {
  errorEmail: false
};

export default EmailForm;
