import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { compose } from 'ramda';
import { func, object, string } from 'prop-types';
import { connect } from 'react-redux';
import LoginForm from './LoginForm';
import { assetize } from '../../utils/pureUtils';
import { NotLogged } from '../../utils/Authorizations';
import { withRouter, useRouter } from '../../utils/router';
import { saveTokenInCookie } from '../../components/auth/utils';

import Img from '../atoms/Img';

const StyledTableIllu = styled(Img)(() => ({
  position: 'fixed',
  width: '60%',
  maxWidth: '866px',
  left: 0,
  bottom: '-6px',
  zIndex: 0,
  pointerEvents: 'none',
  '@media (max-width: 900px)': {
    display: 'none'
  },
  '@media (max-height: 700px)': {
    display: 'none'
  }
}));

// eslint-disable-next-line react/prop-types
export const PureLogin = ({ login, loadCurrentUser }) => {
  const history = useRouter();
  const tryToLogin = async ({ email, password }) => {
    await login({ email, password });
    loadCurrentUser();
  };
  return (
    <>
      <LoginForm history={history} login={tryToLogin} />
      <StyledTableIllu
        alt="desktop illustration"
        src={assetize('/illustrations/TableIllu.webp')}
        srcRetina={assetize('/illustrations/TableIllu@2x.webp')}
        fallback={assetize('/illustrations/TableIllu@2x.png')}
        fallbackRetina={assetize('/illustrations/TableIllu@2x.png')}
      />
    </>
  );
};

const nextJsrematchDispatch = ({ reauth: { login, loadCurrentUser } }) => ({ login, loadCurrentUser });
export const NextjsLogin = connect(null, nextJsrematchDispatch)(PureLogin);

class Login extends PureComponent {
  componentDidUpdate(prevProps) {
    const { loadCurrentUser, token } = this.props;

    // This is called after the "submit" call
    if (prevProps.token !== token) {
      localStorage.setItem('token', token);
      saveTokenInCookie(token);
      loadCurrentUser();
    }
  }

  render() {
    const { history, login } = this.props;

    return (
      <>
        <LoginForm login={login} />
        <StyledTableIllu
          alt="desktop illustration"
          src={assetize('/illustrations/TableIllu.webp')}
          srcRetina={assetize('/illustrations/TableIllu@2x.webp')}
          fallback={assetize('/illustrations/TableIllu@2x.png')}
          fallbackRetina={assetize('/illustrations/TableIllu@2x.png')}
        />
      </>
    );
  }
}

Login.propTypes = {
  history: object.isRequired,
  login: func,
  token: string,
  loadCurrentUser: func
};

const mapStateToProps = ({ reauth: { token } }) => ({ token });
const rematchDispatch = ({ reauth: { login, loadCurrentUser } }) => ({ login, loadCurrentUser });

export default connect(mapStateToProps, rematchDispatch)(compose(NotLogged, withRouter)(Login));
