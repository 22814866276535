import React from 'react';
import { bool, string } from 'prop-types';
import LabelMessage from '../atoms/LabelMessage';
import { useTranslate } from '../../translate/I18n';

export const must1Letter = (password) => {
  return !!password && /[A-Za-z]/.test(password);
};
export const must1Digit = (password) => {
  return !!password && /\d/.test(password);
};
export const mustMin8Char = (password = '') => {
  return !!password && password.length >= 8;
};

const getType = (isSubmitted, isValid) => {
  if (isValid) {
    return 'success';
  }
  if (!isSubmitted) {
    return 'default';
  }
  return 'error';
};

const PasswordChecker = ({ password, isSubmitted }) => {
  const { t } = useTranslate(['validation']);
  return (
    <>
      <LabelMessage name="oneLetter" withIcon={true} type={getType(isSubmitted, must1Letter(password))}>
        {t('validation:must1Letter')}
      </LabelMessage>
      <LabelMessage name="oneDigit" withIcon={true} type={getType(isSubmitted, must1Digit(password))}>
        {t('validation:must1Digit')}
      </LabelMessage>
      <LabelMessage name="min7car" withIcon={true} type={getType(isSubmitted, mustMin8Char(password))}>
        {t('validation:mustMin8Char')}
      </LabelMessage>
    </>
  );
};

PasswordChecker.propTypes = {
  password: string,
  isSubmitted: bool
};

export const checkPassword = (password) => must1Digit(password) && must1Letter(password) && mustMin8Char(password);

export default PasswordChecker;
