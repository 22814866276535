import { pathOr } from 'ramda';

const translateError = (t, inputError, translationParams) => {
  if (inputError?.isTranslated) {
    return inputError?.message;
  }

  if (inputError?.message) {
    const translationKey = inputError.message.includes(':') ? inputError.message : `validation:${inputError.message}`;
    return t(translationKey, translationParams);
  }
  if (inputError?.type) {
    return t(`validation:${inputError.type}`, translationParams);
  }

  return '';
};

const errorPath = (name = '') => name.replace('[', '.').replace(']', '').split('.');

/**
 * @deprecated If you need this, it's because you probably don't use Joi. Please, use JOI to validate form
 * @param t
 * @param name
 * @param errors
 * @param translationParams
 * @return {*|string|string}
 */
export const errorMessageOf = (t, name, errors, translationParams = {}) => {
  const errorKey = pathOr(null, errorPath(name), errors);
  return errorKey ? translateError(t, errorKey, translationParams) : '';
};
