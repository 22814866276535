import React, { useContext } from 'react';
import { object } from 'prop-types';
import { withLastLocation as withReactLastLocation } from 'react-router-last-location';
import { toast } from 'react-toastify';
import { any, compose, not, pickAll, propOr, values } from 'ramda';

import { useTranslate } from '../translate/I18n';
import { UserContext } from '../components/auth/hook/userContext';

import { ROLES, STATUS } from '../constants';
import { commonRoutes } from '../routes';
import { Redirect, withRouter } from './router';
import { isNextJsMode } from './config';

const withLastLocation = WrappedComponent => {
  if (isNextJsMode()) {
    return props => <WrappedComponent {...props} lastLocation={{}} />;
  }
  return withReactLastLocation(WrappedComponent);
};

const {
  COMPANY,
  COMPANY_SUB_ROLES: { CORPORATE },
  FREELANCE
} = ROLES;
const { USER } = STATUS;

const userHasRequiredRoles = (roles, user) =>
  !roles.length || roles.includes(user.role) || roles.includes(user.subRole);

export const userHasAllRequiredStatus = (status, registrationStatus) =>
  !registrationStatus || !compose(any(not), values, pickAll(status), propOr({}, 'values'))(registrationStatus);

const auth = rules => (WrappedComponent, componentProps = {}) => {
  const Sub = props => {
    const user = useContext(UserContext);
    const { t } = useTranslate(['validation']);
    const { location, lastLocation } = props;
    const { roles = [], status = [] } = rules;
    const isLogged = propOr(false, 'isLogged', user);

    if (status.includes(USER.NOT_LOGGED)) {
      return isLogged ? (
        <Redirect to={commonRoutes.home.to} />
      ) : (
        <WrappedComponent user={user} {...props} {...componentProps} />
      );
    }
    if (!isLogged && location.pathname !== '/') {
      return <Redirect to="/" />;
    }

    if (!userHasRequiredRoles(roles, user)) {
      toast.error(t('validation:notAuthorized'));

      return <Redirect to={propOr(commonRoutes.root.to, 'pathname', lastLocation)} />;
    }

    return <WrappedComponent user={user} {...props} {...componentProps} />;
  };

  Sub.propTypes = {
    rules: object,
    location: object.isRequired,
    lastLocation: object
  };
  return Sub;
};

export const Authorization = (rules = {}) => compose(withLastLocation, withRouter, auth(rules));

export const IsLogged = Authorization();
export const NotLogged = Authorization({ status: [USER.NOT_LOGGED] });
export const IsCompany = Authorization({ roles: [COMPANY] });
export const IsCorporate = Authorization({ roles: [CORPORATE] });
export const IsFreelance = Authorization({ roles: [FREELANCE] });
