import React, { useEffect } from 'react';
import { bool, func, node, object, oneOfType, string } from 'prop-types';
import styled from 'styled-components';
import Modal from 'react-modal';
import { layout, space } from 'styled-system';

import Button from '../atoms/Button';

import Cross from '../atoms/icons/Cross';

// TODO remove !important once all modals are transformed into this component

const StyledModal = styled(Modal)(
  ({ theme, fullpage, maxWidth }) => ({
    top: fullpage ? '0 !important' : '10% !important',
    left: '50% !important',
    right: 'auto !important',
    padding: '0px !important',
    border: 'none !important',
    width: fullpage ? '100% !important' : '1280px !important',
    minHeight: fullpage ? '100vh !important' : 'auto !important',
    maxHeight: 'none !important',
    maxWidth: maxWidth ? maxWidth : '100% !important',
    marginBottom: '200px !important',
    position: 'absolute !important',
    transform: 'translateX(-50%) !important',
    outline: 'none !important',
    borderRadius: fullpage ? '0 !important' : `${theme.radii[2]}px !important`,
    boxShadow: fullpage ? 'none !important' : `0 16px 47px ${theme.colors.transparents[7]} !important`,
    background: `${theme.colors.white} !important`
  }),
  layout,
  space
);

const StyledClose = styled(Button)(({ theme }) => ({
  position: 'absolute',
  right: theme.space[2],
  top: theme.space[1],
  paddingLeft: theme.space[1],
  paddingRight: 0,
  fontSize: '26px',
  cursor: 'pointer',
  zIndex: 1
}));

const ModalPopup = ({ isOpen, close, children, fullpage, maxWidth, ...props }) => {
  useEffect(() => {
    if (!isOpen) {
      return;
    }
    const handleKeyPress = e => {
      if (e.keyCode === 27 || e.key === 'Escape') {
        close();
      }
    };
    window.addEventListener('keyup', handleKeyPress, false);
    return () => {
      window.removeEventListener('keyup', handleKeyPress, false);
    };
  }, [close, isOpen]);

  useEffect(() => {
    Modal.setAppElement('#main');
  }, []);

  return (
    <StyledModal
      isOpen={isOpen}
      fullpage={fullpage}
      maxWidth={maxWidth}
      tabIndex="0"
      {...props}
      style={{
        overlay: {
          zIndex: 101,
          overflow: 'auto',
          background: 'rgba(67, 74, 84, .7)'
        }
      }}>
      <StyledClose styleType="transparent" onClick={close} data-cy="modal-close">
        <Cross />
      </StyledClose>
      {children}
    </StyledModal>
  );
};

ModalPopup.propTypes = {
  isOpen: bool.isRequired,
  close: func.isRequired,
  children: node,
  fullpage: bool,
  maxWidth: oneOfType([string, object])
};

export default ModalPopup;
