/* eslint-disable max-lines */
import React from 'react';
import Labelize from './Labelize';
import PasswordField from '../../atoms/inputField/PasswordField';

const InputPasswordWithLabel = React.forwardRef(({ label, errorMessage, showError, ...props }, ref) => {
  return (
    <Labelize label={label} errorMessage={errorMessage} showError={showError} inputName={props.name}>
      <PasswordField ref={ref} {...props} />
    </Labelize>
  );
});

InputPasswordWithLabel.propTypes = {
  ...PasswordField.propTypes,
  ...Labelize.propTypes
};

export default InputPasswordWithLabel;
