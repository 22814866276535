import React from 'react';
import { bool, func } from 'prop-types';
import { toast } from 'react-toastify';
import { useTranslate } from '../../translate/I18n';

import Box from '../atoms/Box';
import Text from '../atoms/Text';

import Modal from '../molecules/Modal';
import EmailForm from '../elements/EmailForm';
import { client } from '../../apollo/client';

const sendEmail = async email => {
  const { default: forgotPassword } = await import('../../graphql/auth/forgotPassword');

  await client.mutate({
    mutation: forgotPassword,
    variables: { email }
  });
};

const ModalForgotPassword = ({ isOpen, onClose, send = sendEmail }) => {
  const { t } = useTranslate(['notificationSuccess', 'auth', 'common']);

  const sendForgotPassword = async email => {
    send(email)
      .then(() => {
        toast.success(t('notificationSuccess:auth.forgotPassword'));
        onClose();
      });
  };

  return (
    <Modal isOpen={isOpen} close={onClose}>
      <Box py={3} px={5}>
        <Text tag="h1" styleName="wH3" mb={3}>
          {t('auth:forgotPassword')}
        </Text>
        <Text mb={2}>{t('auth:forgotPasswordDesc')}</Text>
        <EmailForm onFormSubmit={sendForgotPassword} />
      </Box>
    </Modal>
  );
};

ModalForgotPassword.propTypes = {
  isOpen: bool,
  onClose: func,
  send: func
};
export default ModalForgotPassword;
