import React from 'react';
import { hackyServerSideTranslations } from '../i18nNext';
import { NextjsLogin } from '../components/auth/Login';
import { buildGuestOnlyLayout } from '../components/layout/layoutBuilder';
import { buildSimpleHead } from '../components/layout/heading';

const LoginPage = () => {
  return <NextjsLogin />;
};

export async function getStaticProps({ locale }) {
  const i18nConfig = await hackyServerSideTranslations(locale);
  return { props: { ...i18nConfig } };
}

LoginPage.getHead = () => buildSimpleHead('login');
LoginPage.getLayout = buildGuestOnlyLayout();
export default LoginPage;
